<template>
    <div class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" style="width: 800px; overflow: auto; max-height: 85%;">
      <div class="relative">
        <div>
          <div class="bg-primary flex justify-between p-3">
            <div class=" font-medium text-white heading-3">
                {{dialogTtile}}
            </div>
          </div>
          <div class="p-4">
            <div class="sm:grid grid-cols-12 gap-3">
               <div v-if="dialogTtile !== 'Update Hot Notes'" class="col-span-12 mb-4 sm:mb-0">
                <TextField
                  :inputId="'companyNameid'"
                  :textMaxlength="50"
                  :inputext="updateCustObj.companyName"
                  :placholderText="`Company Name`"
                  :showcharLimit="true"
                  :lableText="'Enter Company Name'"
                  @keyPressAction="companyNameError = ''"
                  :fieldError="companyNameError !== ''"
                  :autoFocus="false"
                  @inputChangeAction="(data) => updateCustObj.companyName = data"  />
                  <p class="text-error heading-7">{{companyNameError}}</p>
               </div>
                <!-- <div class=" col-span-6 mb-4 sm:mb-0 ">
                    <div class="pr-4 label_css">
                        Rating
                    </div>
                    <star-rating :star-size="40" active-color="#107591" inactive-color="#1295ba0f" v-model="updateCustObj.rating" :show-rating="false"></star-rating>
                 
                </div> -->
                <div class=" col-span-12 mb-4">
                    <TextAreaInput
                      :inputId="'discriptioncon'"
                      :inputext="updateCustObj.hotNotes"
                      :placholderText="`Write notes here..`"
                      :lableText="'Hot Notes'"
                      :inputType="'text'"
                      :autoFocus="false"
                      :textMaxlength="1000"
                      :showcharLimit="true"
                      :cols="50"
                      :rows="3"
                      :setReadOnly="false"
                      @inputChangeAction="(data) => updateCustObj.hotNotes = data"  />
                </div>
            </div>
          </div>
        </div>
          <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
            <div class="text-rigth flex gap-2 mb-3">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="discard()"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Update'" @buttonAction="updateCustomer()"/>
            </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
// import StarRating from 'vue-star-rating'
import Button from '@/View/components/globalButton.vue'
import TextField from '@/View/components/textfield.vue'
import TextAreaInput from '@/View/components/textAreaInput.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
  name: 'sendEmail',
  components: {
    TextAreaInput,
    // StarRating,
    Button,
    TextField
  },
  data () {
    return {
        updateCustObj: {
            customerId: 0,
            companyName: '',
            rating: 0,
            hotNotes: '',
        },
        companyNameError: ''
    }
  },
  props: ['updateCustData', 'dialogTtile'],
   created() {
  },
  mounted () {
    document.body.style = 'overflow: hidden;'
    this.updateCustObj.companyName = this.updateCustData.companyName
    this.updateCustObj.rating = this.updateCustData.rating
    this.updateCustObj.hotNotes = this.updateCustData.hotNotes
    this.updateCustObj.customerId = this.updateCustData.customerId
  },
  watch: {},
  methods: {
    updateCustomer () {
      let isValid = true
      if (this.updateCustObj.companyName.trim() === '') {
        isValid = false
        this.companyNameError = 'Company name is required'
      }
      if (isValid) {
         MyJobApp.updateCustomer(
         this.updateCustObj,
          response => {
            document.body.style = 'overflow: visible;'
            this.$root.$emit('updateCustHandler', true)
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            document.body.style = 'overflow: visible;'
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
      }
    },
    discard () {
      document.body.style = 'overflow: visible;'
      this.$root.$emit('updateCustHandler', false)
    },
  },
  beforeDestroy () {
    // this.$root.$off('closeProfileDialog')
  }
}
</script>
<style scoped>

</style>