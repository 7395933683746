<template>
  <div class="mt-4">
    <div
      class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl w-full"
    >
      <div class="flex justify-center items-center">
        <h1 class="heading-2 text-center">
          {{
            parseInt(this.$route.params.requestId)
              ? "Edit Request"
              : "Create Request"
          }}
        </h1>
        <div class="flex items-center gap-3"></div>
      </div>
    </div>
    <div class="flex justify-center">
      <div
        class="card rounded-xl bg-white p-4 my-4 w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12"
      >
        <tabs :tabsList="tabTitle" />
        <div class="p-4 pb-0">
          <div v-show="tabTitle[0].active" class="sm:grid grid-cols-2 gap-4">
            <div class="mb-4 sm:mb-0" >
              <Dropdown
                :disabled="isDisable"
                :inputext="searchForCustomer"
                :inputId="'searchreq-Customer'"
                :lableText="'Customer/Lead'"
                :placholderText="`Customer/Lead`"
                :autoFocus="false"
                :showArrow="true"
                :setReadOnly="false"
                :searchCallApi="true"
                :fieldError="errObj.custErr !== ''"
                :showPlus="true"
                :keyName="'companyName'"
                :listId="'customerlistid'"
                :items="customersList"
                @enterkeyPressAction="errObj.custErr = ''"
                @inputChangeAction="callsearchCust"
                @addNew="addNewCustomer"
                @selectNewForVal="selectNewCustomer"
              />
              <p v-if="errObj.custErr !== ''" class="text-error heading-7">
                {{ errObj.custErr }}
              </p>
            </div>
            <div
              v-if="custDetail !== null"
              class="mb-4 sm:mb-0 flex items-center"
            >
              <div
                v-if="custDetail.hotNotes !== ''"
                class="flex items-center ml-4  pt-2"
              >
                <span
                  @click="showAddNote = true"
                  class="text-primary cursor-pointer"
                  ><i class="fa-solid fa-pen-to-square h-5 w-5"></i
                ></span>
              </div>
              <div
                v-if="!showHotNote && custDetail.hotNotes !== ''"
                class="flex items-center px-4 pt-2"
              >
                <span
                  @click="showHotNote = true"
                  class="text-primary cursor-pointer"
                  >Show Hot Notes</span
                >
              </div>
              <div v-else-if="showHotNote" class="flex items-center px-4 pt-2">
                <span
                  @click="showHotNote = false"
                  class="text-primary cursor-pointer"
                  >Hide Hot Notes</span
                >
              </div>
              <div
                v-else-if="custDetail.hotNotes === ''"
                class="flex items-center px-4 pt-2"
              >
                <span
                  @click="showAddNote = true"
                  class="text-primary cursor-pointer"
                  >Enter Hot Notes</span
                >
              </div>
            </div>
              <div
              v-if="custDetail !== null && showHotNote"
              class="mb-4 sm:mb-0 flex items-center col-span-2"
            >
              <div>
                <p class="text-error">{{ custDetail.hotNotes }}</p>
              </div>
            </div>
            <div class="mb-4 sm:mb-0">
              <TextField
                :inputId="'requtitleid'"
                :textMaxlength="100"
                :showcharLimit="true"
                :inputext="requetsObj.requestTitle"
                :fieldError="errObj.TitleError !== ''"
                @enterkeyPressAction="errObj.TitleError = ''"
                :placholderText="`Request Title`"
                :lableText="'Request Title'"
                @blurAction="errObj.TitleError = ''"
                :autoFocus="false"
                @inputChangeAction="(data) => (requetsObj.requestTitle = data)"
              />
              <p v-if="errObj.TitleError !== ''" class="text-error heading-7">
                {{ errObj.TitleError }}
              </p>
            </div>
          <div v-if="requetsObj.customerId > 0 && requetsObj.fullAddress === ''"
            class="mb-4 sm:mb-0 col-span-2 text-text1"
          >
            <p class="mb-1">
              Location
              <span
                v-if="!isOfficeAddressSel && !isAddedNewAddress"
                class="text-error text"
                >Required</span
              >
            </p>
            <div class="col-span-2">
              <div
                @click="checkboxCheck()"
                class="cursor-pointer hover:bg-screen_bg mb-2 cust_card p-2 text-text1"
              >
                <div class="flex items-center">
                  <div class="flex gap-1">
                    <p v-if="!isOfficeAddressSel" class="text-gray3">
                      <i class="fa-regular fa-circle h-5 w-5"></i>
                    </p>
                    <span v-else class="text-primary">
                      <i class="fa-solid fa-circle-check h-5 w-5"></i>
                    </span>
                    <span class="">At Office Location </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-2">
              <div
                @click="selsectFromMap"
                class="cursor-pointer hover:bg-screen_bg mb-2 cust_card p-2 text-text1"
              >
                <div class="flex items-center justify-between">
                  <div class="flex gap-1">
                    <p v-if="!isAddedNewAddress" class="text-gray3 mb-0">
                      <i class="fa-regular fa-circle h-5 w-5"></i>
                    </p>
                    <span v-else class="text-primary">
                      <i class="fa-solid fa-circle-check h-5 w-5"></i>
                    </span>
                    <span class="">Select From Map </span>
                  </div>
                  <div>
                    <p v-if="isAddedNewAddress">{{ otherLocationDistance }}</p>
                  </div>
                </div>
                <div v-if="isAddedNewAddress" class="">
                  {{ requetsObj.fullAddress }}
                  <div v-if="isAddedNewAddress" class="flex items-center">
                    <CheckBoxInput
                      :checkValue="requetsObj.isAddressAddToCustomer"
                      :label="``"
                      :size="'small'"
                      :disabled="false"
                      @setIsActive="
                        requetsObj.isAddressAddToCustomer =
                          !requetsObj.isAddressAddToCustomer
                      "
                    />
                    <span
                      class="pl-0 text-text2 cursor-pointer"
                      @click.stop.prevent="
                        requetsObj.isAddressAddToCustomer =
                          !requetsObj.isAddressAddToCustomer
                      "
                      >Add address in customer file</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <p v-if="errObj.addErr !== ''" class="text-error heading-7">
              {{ errObj.addErr }}
            </p>
          </div>
          <div v-else-if="requetsObj.fullAddress === ''" class="col-span-2">
            <div @click="checkboxCheck()" class="mb-2 cust_card p-2 text-text1">
              <div class="">
                <p>Location <span class="text-error text">Required</span></p>
                <p class="text-gray3 heading-7">
                  Please select Customer/Lead first
                </p>
              </div>
            </div>
          </div>
          <div v-else class="col-span-2">
            <div class="mb-2 cust_card p-2 text-text1">
              <div class="flex justify-between items-center">
                <p class="text-gray3">Property Location <span @click="deleteSelectedAddress" class="text-error ml-2 heading-5 cursor-pointer">
                  <i class="fa-solid fa-trash-can"></i>
                  </span></p>
                <p @click="isSelectFromMap = true" class="text-primary heading-5 cursor-pointer">
                  <i class="fa-solid fa-pen-to-square"></i>
                </p>
              </div>
              <div class="">
                  {{ requetsObj.fullAddress }}
                  <div v-if="isAddedNewAddress" class="flex items-center">
                    <CheckBoxInput
                      :checkValue="requetsObj.isAddressAddToCustomer"
                      :label="``"
                      :size="'small'"
                      :disabled="false"
                      @setIsActive="
                        requetsObj.isAddressAddToCustomer =
                          !requetsObj.isAddressAddToCustomer
                      "
                    />
                    <span
                      class="pl-0 text-text2 cursor-pointer"
                      @click.stop.prevent="
                        requetsObj.isAddressAddToCustomer =
                          !requetsObj.isAddressAddToCustomer
                      "
                      >Add address in customer file</span
                    >
                  </div>
                </div>
            </div>
          </div>
            <div class="flex justify-end col-span-2">
                <div class="text-rigth flex gap-2">
                  <Button
                    :btnSize="'medium'"
                    :textColor="'text1'"
                    :btnColor="'gray1'"
                    :btnText="'Cancel'"
                    @buttonAction="$router.go(-1)"
                  />
                  <Button
                    :btnSize="'medium'"
                    :textColor="'white'"
                    :btnColor="'success'"
                    :btnText="'Next'"
                    @buttonAction="createRequest()"
                  />
                </div>
            </div>
          </div>
          <div v-show="tabTitle[1].active" class="sm:grid grid-cols-2 gap-4">
          <!-- <div  class="grid grid-cols-2 gap-4"> -->
             
            <div class="mb-4 sm:mb-0">
              <TextField
               :inputId="`bugetamout`"
               :inputext="stepTwoObj.budgetAmount"
               :placholderText="`Enter Budget Amount`"
               :lableText="'Budget:'"
               :inputType="'number'"
               :textRight="true"
               :autoFocus="false"
               :setReadOnly="false"
               :inputTypeText="'number'"
               @inputChangeAction="(data) => stepTwoObj.budgetAmount = data" />
            </div>
            <div class="mb-4 sm:mb-0">
            <TextField
              :placholderText="`Date`"
              :setReadOnly="true"
              :autoFocus="false"
              :inputext="stepTwoObj.decisionBy"
              :showDate="true"
              :lableText="'Decision by'"
              :inputId="'Decisionbyid'"
              @onClickInputAction="decisionDateShow = true"
            />
            <div v-if="decisionDateShow" class="popup_overlay">
              <div
                style="height: 435px; width: 306px"
                class="custom_dialog rounded-xl border border-gray4 ring-gray4"
              >
                <DateTimePickerCustom
                  v-model="stepTwoObj.decisionBy"
                  v-if="decisionDateShow"
                  :showDate="true"
                  :title="'Decision by'"
                  :showOnlyDate="true"
                  :showStartDate="true"
                  :projectSection="true"
                  @closeDateTimepickerDialog="decisionDateShow = false"
                >
                </DateTimePickerCustom>
              </div>
            </div>
          </div>

            <div class="mb-4">
                <MultiSelect 
                :inputext="employeeSearchText" 
                :inputId="'requestassignemp'"
                :lableText="'Assign to'"
                :placholderText="`Select Employee`"
                :autoFocus="false"
                :showArrow="true"
                :setReadOnly="false"
                :searchCallApi="false"
                :showPlus="false"
                :keyName="'fullName'"
                :idKey="'userDetailId'"
                :sectionId="'requesectionlistid'"
                :listId="'empreqlistid'"
                :items="employeeList"
                :selectedValues="selectedEmployee"
                @selectNewForVal="selectEmployee"
                />
                <p v-if="errObj.assingeeErr !== ''" class="text-error heading-7">
                  {{ errObj.assingeeErr }}
                </p>
            </div>

            <div class="mb-4 sm:mb-0 col-span-2">
            <TextAreaInput
            :inputId="'instructionofrequest'"
            :inputext="stepTwoObj.serviceDetail"
            :placholderText="`Write description here..`"
            :lableText="'Details of requirement'"
            :inputType="'text'"
            :autoFocus="false"
            :cols="50"
            :rows="3"
            :setReadOnly="false"
            @inputChangeAction="(data) => stepTwoObj.serviceDetail = data"  />
              
          </div>
            
            <div class="flex justify-end col-span-2">
                <div class="text-rigth flex gap-2">
                  <Button
                    :btnSize="'medium'"
                    :textColor="'text1'"
                    :btnColor="'gray1'"
                    :btnText="'Previous'"
                    @buttonAction="goStepOne"
                  />
                  <Button
                    :btnSize="'medium'"
                    :textColor="'white'"
                    :btnColor="'success'"
                    :btnText="'Save'"
                    @buttonAction="saveStepTwo(true)"
                  />
                </div>
              </div>
          </div>
        </div>
        
      </div>
    </div>
    <AddCustomer v-if="addCustomer" />
    <AddressAdd :showAdditional="true" v-if="addNewReqAdd" />
    <AddUpdateShedule
      :requetsObj="requetsObj"
      :requestId="requetsObj.requestId"
      :selectedTeam="selectedTeam"
      :assementObj="assementObj"
      :addUpdateshedule="true"
      v-if="addUpdateshedule"
    />
    <ReqAddressAddUpdate
      :addressList="addressList"
      :AddressObj="requetsObj"
      v-if="isSelectFromMap"
    />
     <update-company-info
      v-if="showAddNote"
      :dialogTtile="'Update Hot Notes'"
      :updateCustData="custDetail"
    />
  </div>
</template>
<script>
import DateTimePickerCustom from "@/View/components/dateTimepicker";
import CheckBoxInput from "@/View/components/checkBoxInput.vue";
// import TextAreaInput from "@/View/components/textAreaInput.vue";
// import SheduleVisit from './components/SheduleVisit.vue'
import TextField from "@/View/components/textfield.vue";
import MyJobApp from "@/api/MyJobApp.js";
import Button from "@/View/components/globalButton.vue";
import Dropdown from "@/View/components/dropdown.vue";
import AddCustomer from "@/View/customer/components/AddCustomer.vue";
import deboucneMixin from "@/mixins/debounce.js";
import moment from "moment";
import AddressAdd from "@/View/components/AddressAdd.vue";
import AddUpdateShedule from "./AddUpdateShedule.vue";
import ReqAddressAddUpdate from "./ReqAddressAddUpdate.vue";
import timeZoneWithCountryCode from "@/assets/timeZoneWithCountryCode.json";
import milesCountry from "@/assets/milesCountry.json";
import Tabs from '../../components/tabs.vue';
import TextAreaInput from '../../components/textAreaInput.vue';
import MultiSelect from '@/View/components/multipleSelectDrop.vue'
import UpdateCompanyInfo from '../../customer/components/UpdateCompanyInfo.vue';

export default {
  name: "customer",
  components: {
    DateTimePickerCustom,
    CheckBoxInput,
    UpdateCompanyInfo,
    MultiSelect,
    TextField,
    AddUpdateShedule,
    Button,
    AddCustomer,
    AddressAdd,
    Dropdown,
    ReqAddressAddUpdate,
    Tabs,
    TextAreaInput,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      showAddNote: false,
      showHotNote: false,
      custDetail: null,
      reqTempObj: null,
      decisionDateShow: false,
      employeeList: [],
      selectedEmployee: [],
        employeeSearchText: '',
      stepTwoObj: {
        requestId: 0,
        serviceDetail: '',
        assignToList: [],
        decisionBy: '',
        budgetAmount: 0
      },
      tabTitle: [
        {
          name: "Customer & Location",
          complete: false,
          active: true,
          disabled: false,
          canViewPermission: true,
        },
        {
          name: "Description & Assingee",
          complete: false,
          active: false,
          disabled: true,
          canViewPermission: true,
        },
      ],
      otherLocationDistance: "",
      LoginUserlatLong: null,
      OfficelatLong: null,
      isAddedNewAddress: false,
      isSelectFromMap: false,
      addReqDetail: false,
      isOfficeAddressSel: false,
      addNewReqAdd: false,
      islead: false,
      allDay: false,
      isDisable: false,
      jobDisplayAdd: "",
      selectedTeam: [],
      customersList: [],
      addCustomer: false,
      showAddSelectAddr: false,
      availibilityDateShow: false,
      addUpdateshedule: false,
      otherAvDateShow: false,
      anyTime: false,
      morning: false,
      afternoon: false,
      evening: false,
      addressList: [],
      searchForCustomer: "",
      searchApitext: "",
      errObj: {
        custErr: "",
        addErr: "",
        TitleError: "",
        assingeeErr: "",
      },
      requetsObj: {
        requestId: 0,
        customerId: 0,
        requestTitle: "",
        serviceDetail: "",
        instructions: "",
        availabilityDate: "",
        otherAvailDate: "",
        arrivalTime: "",
        startTime: "",
        endTime: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        latitude: 0,
        longitude: 0,
        geofenceRangeDiameter: 0,
        fullAddress: "",
        houseNo: "",
        floorNumber: "",
        addressTitle: "",
        buildingName: "",
        assignToList: [],
        isAddressAddToCustomer: false,
        isUnschedule: false,
        isScheduled: false,
      },
      assementObj: {
        instructions: "",
        isUnschedule: true,
        isAnyTime: false,
        startDateTime: "",
        endDateTime: "",
        isSchedule: false,
      },
      getCustList: () => {},
      isTimerRunningStepTwo: false,
    };
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler());
  },
  computed: {},
   watch: {
    tabTitle: {
      handler() {
        if (this.tabTitle[0].active) {
          clearInterval(this.intervalIdStepTwo);
          this.isTimerRunningStepTwo = true
        }
        if (this.tabTitle[1].active) {
          clearInterval(this.intervalIdStepTwo);
          this.isTimerRunningStepTwo = true
          this.showStepThree = true
          this.StartTimerSt2()
        }
      }, deep: true
    },
    timeCounter: {
      handler() {
      }, deep: true
    }
  },
  mounted() {
    this.$root.$on("updateCustHandler", (response) => {
      this.showAddNote = false;
      if (response) {
        this.getCustDetails(this.custDetail.customerId);
      }
    });
    this.$root.$on("setNewTabActive", (index) => {
      this.tabsSet(index);
    });
    if (this.$route.query.requestId > 0) {
    this.$router.push({name: 'requestDetail', params: {requestId: parseInt(this.$route.query.requestId)}})
    }
    if (this.$route.query.requestId > 0) {
      this.geRequestDetail(this.$route.query.requestId)
    }
    this.LoginUserlatLong = JSON.parse(
      localStorage.getItem("LoginUserlatLong")
    );
    this.OfficelatLong = JSON.parse(localStorage.getItem("orgInfo"));
    if (this.$route.query.customerId > 0) {
      this.isDisable = true;
      this.getCustLeadInfo(parseInt(this.$route.query.customerId));
    }
    console.log("route", this.$route.query);
    this.getCustList = this.debounce(function () {
      this.getCustomerList();
    }, 500);
    this.getCustomerList();
    this.$root.$on("addCustomerHandler", (response, data) => {
      if (response && this.addCustomer) {
        this.selectNewCustomer(data);
      }
      this.addCustomer = false;
    });
    this.$root.$on("ReqaddreHandler", (response, data, isNew) => {
      if (response && data !== null && this.isSelectFromMap) {
        if (isNew) {
          let count = 0
          this.addressList.forEach(e => {
            if (e.latitude === data.latitude && e.longitude === data.longitude) {
              count += 1
            }
          })
          if (count === 0) {
            this.isAddedNewAddress = true;
          }
          
        } else {
          this.isAddedNewAddress = false;
          this.requetsObj.isAddressAddToCustomer = false
        }
        this.setAddress(data);
      }
      this.isSelectFromMap = false;
    });
    this.$root.$on("closeScheduleHandler", (response, data) => {
      if (data !== null && response) {
        this.assementObj = data;
        this.selectedTeam = data.selectedTeam;
        var startTime = moment(data.startDateTime);
        var endTime = moment(data.endDateTime);
        var hoursDiff = endTime.diff(startTime, "hours");
        var minutesDiff = endTime.diff(startTime, "minutes") % 60;
        if (
          (hoursDiff === 23 && minutesDiff === 59) ||
          (hoursDiff === 24 && minutesDiff === 0)
        ) {
          this.allDay = true;
        } else {
          this.allDay = false;
        }
      }
      this.addUpdateshedule = false;
      document.body.style = "overflow: visible;";
    });
  },
  beforeDestroy() {
    this.$root.$off("ReqaddreHandler");
    this.$root.$off("closeScheduleHandler");
    this.$root.$off("addCustomerHandler");
  },
  methods: {
    StartTimerSt2() {
      if (this.isTimerRunningStepTwo) {
        this.intervalIdStepTwo = setInterval(this.callApiStepTwo, 10000);
      } else {
        clearInterval(this.intervalIdStepTwo);
      }
    },
    callApiStepTwo() {
      if (this.reqTempObj.serviceDetail !== this.stepTwoObj.serviceDetail.trim() || this.reqTempObj.budgetAmount !== parseInt(this.stepTwoObj.budgetAmount) || moment(this.reqTempObj.decisionBy).format("YYYY-MM-DD") !== moment(this.stepTwoObj.decisionBy).format("YYYY-MM-DD") ) {
        this.saveStepTwo()
      }
    },
    StopApiStepTwo() {
      clearInterval(this.intervalIdStepTwo);
      this.isTimerRunningStepTwo = false;
    },
    tabsSet(index) {
      this.tabTitle.forEach((element, i) => {
        if (index === i) {
          element.active = true;
        } else {
          element.active = false;
        }
      });
    },
     selectEmployee (emplist) {
      this.errObj.assingeeErr = ''
      this.selectedEmployee = []
      this.stepTwoObj.assignToList = []
      let tempEmpList = []
      emplist.map((data) => {
        tempEmpList.push({userDetailId:data.userDetailId})
        this.selectedEmployee.push(data)
      })
      this.stepTwoObj.assignToList = tempEmpList
    },
    goStepOne () {
      this.tabTitle[0].active = true
      this.tabTitle[1].active = false
    },
    deleteSelectedAddress () {
      this.errObj.addErr = "";
      this.jobDisplayAdd = "";
      this.requetsObj.fullAddress = "";
      this.requetsObj.addressLine1 = "";
      this.requetsObj.addressLine2 = "";
      this.requetsObj.city = "";
      this.requetsObj.state = "";
      this.requetsObj.zip = "";
      this.requetsObj.latitude = 0;
      this.requetsObj.longitude = 0;
      this.requetsObj.country = "";
      this.requetsObj.directionNote = "";
      this.requetsObj.houseNo = "";
      this.requetsObj.floorNumber = "";
      this.requetsObj.buildingName = "";
      this.isAddedNewAddress = false
      this.isOfficeAddressSel = false
    },
    clearAssmnt() {
      this.assementObj.instructions = "";
      this.assementObj.isUnschedule = true;
      this.assementObj.isAnyTime = false;
      this.assementObj.startDateTime = "";
      this.assementObj.endDateTime = "";
      this.assementObj.isSchedule = false;
    },
    createRequest () {
      console.log('check 111');
      let data = {
        requestId: this.requetsObj.requestId,
        customerId: this.requetsObj.customerId,
        requestTitle: this.requetsObj.requestTitle,
        addressLine1: this.requetsObj.addressLine1,
        addressLine2: this.requetsObj.addressLine2,
        city: this.requetsObj.city,
        state: this.requetsObj.state,
        zip: this.requetsObj.zip,
        country: this.requetsObj.country,
        latitude: this.requetsObj.latitude,
        longitude: this.requetsObj.longitude,
        geofenceRangeDiameter: this.requetsObj.geofenceRangeDiameter,
        fullAddress: this.requetsObj.fullAddress,
        houseNo: this.requetsObj.houseNo,
        floorNumber: this.requetsObj.floorNumber,
        buildingName: this.requetsObj.buildingName,
        isAddressAddToCustomer: this.requetsObj.isAddressAddToCustomer,
        directionNote: this.requetsObj.directionNote,
        contactNumber: this.requetsObj.contactNumber,
        addressTitle: this.requetsObj.addressTitle,
        contactName: this.requetsObj.contactName
      }
      let isValid = true;
      if (data.customerId === 0) {
        console.log('check 222', isValid);
        this.errObj.custErr = "Please select customer";
        isValid = false;
      }
      if (data.requestTitle.trim() === '') {
        console.log('check 333', isValid);
        this.errObj.TitleError = "Request title is required";
        isValid = false;
      }
      if (!this.isOfficeAddressSel) {
        if ( this.showAddSelectAddr && parseInt(this.$route.params.requestId) === 0 ) {
          if ( data.latitude === 0 && data.longitude === 0 ) {
            console.log('check 4444', isValid);
            this.errObj.addErr = "Please select Property Location";
            isValid = false;
          }
        } else if (!this.isAddedNewAddress && !this.isOfficeAddressSel && this.reqTempObj ==- null) {
          console.log('check 5555', isValid);
            this.errObj.addErr = "Please select Property Location";
            isValid = false;
        }
      }
      console.log('check 111', isValid);
      if (isValid) {
        this.$store.dispatch("SetLoader", {
          status: true,
          message: "Loading...",
        });
        MyJobApp.saveRequestStepOne(
          data,
          (response) => {
            this.tabTitle[0].disabled = false
            this.tabTitle[1].disabled = false
            this.tabTitle[0].active = false
            this.tabTitle[1].active = true
            this.tabTitle[0].complete = true;
            this.$store.dispatch("SetAlert", {
              showAlert: true,
              message: response.message,
              color: "success",
            });
            this.$router.push({path:"/request/addNewRequest",query:{ requestId: response.Data },})
            .catch(err => {  if (err.name !== 'NavigationDuplicated') {    throw err;  }});
            this.geRequestDetail(response.Data)
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          },
          (error) => {
            this.$store.dispatch("SetAlert", {
              showAlert: true,
              message: error.message,
              color: "error",
            });
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        );
      }
    },
    saveStepTwo (goOnDetail) {
      console.log('setetetet2222222222222', this.stepTwoObj);

      let isValid = true;
      if (this.stepTwoObj.assignToList.length === 0) {
        this.errObj.assingeeErr = 'Assignee is required'
        isValid = false
      }
      
      if (isValid) {
        this.stepTwoObj.budgetAmount = parseInt(this.stepTwoObj.budgetAmount )
        if (goOnDetail) {
          this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
        }
        MyJobApp.reqAddStepTwo(
          this.stepTwoObj,
          () => {
            if (goOnDetail) {
              setTimeout(() => {
                this.$router.push({name: 'requestDetail', params: {requestId: this.requetsObj.requestId}})
                this.$store.dispatch("SetLoader", { status: false, message: "" });
              }, 200);
            } else {
              this.geRequestDetail(this.requetsObj.requestId, true)
              this.tabTitle[1].complete = true;
            }
            
          },
          (error) => {
            this.$store.dispatch("SetAlert", {
              showAlert: true,
              message: error.message,
              color: "error",
            });
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        );
      }
    },
    geRequestDetail(requestId, notCallCustomer) {
      MyJobApp.getRequestDetail(
        requestId,
        (response) => {
          this.tabTitle[1].disabled = false
          this.stepTwoObj.requestId = response.Data.requestId 
          this.reqTempObj = response.Data
         this.requetsObj.requestId = response.Data.requestId
         this.requetsObj.customerId = response.Data.customerId
         this.requetsObj.requestTitle = response.Data.requestTitle
         this.requetsObj.addressLine1 = response.Data.addressLine1
         this.requetsObj.addressLine2 = response.Data.addressLine2
         this.requetsObj.city = response.Data.city
         this.requetsObj.state = response.Data.state
         this.requetsObj.zip = response.Data.zip
         this.requetsObj.country = response.Data.country
         this.requetsObj.latitude = response.Data.latitude
         this.requetsObj.longitude = response.Data.longitude
         this.requetsObj.geofenceRangeDiameter = response.Data.geofenceRangeDiameter
         this.requetsObj.fullAddress = response.Data.fullAddress
         this.requetsObj.houseNo = response.Data.houseNo
         this.requetsObj.floorNumber = response.Data.floorNumber
         this.requetsObj.buildingName = response.Data.buildingName
         this.requetsObj.isAddressAddToCustomer = response.Data.isAddressAddToCustomer
         this.requetsObj.directionNote = response.Data.directionNote
         this.requetsObj.contactNumber = response.Data.contactNumber
         this.requetsObj.addressTitle = response.Data.addressTitle
         this.requetsObj.contactName = response.Data.contactName
         if (!notCallCustomer) {
           this.getCustDetails(response.Data.customerId);
           this.getEmployeeList()
         }
          this.searchForCustomer = response.Data.companyName;
          this.islead = response.Data.isLead
         this.$store.dispatch("SetLoader", { status: false, message: "" });

        

        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    crateRequest() {
      // this.noOfVisit = this.requetsObj.visitList.length + 1
      let isValid = true;
      if (this.requetsObj.customerId === 0) {
        this.errObj.custErr = "Please select customer";
        isValid = false;
      }
      if (!this.isOfficeAddressSel) {
        if ( this.showAddSelectAddr && parseInt(this.$route.params.requestId) === 0 ) {
          if ( this.requetsObj.latitude === 0 && this.requetsObj.longitude === 0 ) {
            this.errObj.addErr = "Please select Property Location";
            isValid = false;
          }
        }
      }
      let arrivaltime = [];

      if (this.morning) {
        arrivaltime.push("morning");
      }
      if (this.anyTime) {
        arrivaltime.push("anyTime");
      }
      if (this.afternoon) {
        arrivaltime.push("afternoon");
      }
      if (this.evening) {
        arrivaltime.push("evening");
      }

      this.requetsObj.arrivalTime = arrivaltime.toString();
      this.requetsObj.instructions = this.assementObj.instructions;
      this.requetsObj.startTime = this.assementObj.startDateTime;
      this.requetsObj.endTime = this.assementObj.endDateTime;
      if (this.requetsObj.availabilityDate !== "") {
        this.requetsObj.availabilityDate = new Date(
          this.requetsObj.availabilityDate
        ).toUTCString();
      }
      if (this.requetsObj.otherAvailDate !== "") {
        this.requetsObj.otherAvailDate = new Date(
          this.requetsObj.otherAvailDate
        ).toUTCString();
      }
      this.requetsObj.assignToList = [];
      this.selectedTeam.map((data) => {
        let obj = {
          userDetailId: data.userDetailId,
        };
        this.requetsObj.assignToList.push(obj);
      });
      if (isValid) {
        this.$store.dispatch("SetLoader", {
          status: true,
          message: "Loading...",
        });
        MyJobApp.saveRequest(
          this.requetsObj,
          (response) => {
            this.$router.go(-1);
            this.$store.dispatch("SetAlert", {
              showAlert: true,
              message: response.message,
              color: "success",
            });
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          },
          (error) => {
            this.$store.dispatch("SetAlert", {
              showAlert: true,
              message: error.message,
              color: "error",
            });
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        );
      }
    },
    setAddress(data) {
      let temp = 0;
      let temp3 = "";
      if (this.LoginUserlatLong !== null) {
        temp = this.calcDistance(
          this.LoginUserlatLong.latitude,
          this.LoginUserlatLong.longitude,
          data.latitude,
          data.longitude
        );
        temp3 = ` from user`;
      } else {
        temp = this.calcDistance(
          this.OfficelatLong.latitude,
          this.OfficelatLong.longitude,
          data.latitude,
          data.longitude
        );
        temp3 = ` from office`;
      }
      let value = this.getDistanceUnit(temp * 1000);
      this.otherLocationDistance = value + temp3;

      this.jobDisplayAdd = data !== null ? data.fullAddress : "";
      this.requetsObj.addressLine1 = data !== null ? data.addressLine1 : "";
      this.requetsObj.addressLine2 = data !== null ? data.addressLine2 : "";
      this.requetsObj.city = data !== null ? data.city : "";
      this.requetsObj.state = data !== null ? data.state : "";
      this.requetsObj.zip = data !== null ? data.zip : "";
      this.requetsObj.latitude = data !== null ? data.latitude : 0;
      this.requetsObj.longitude = data !== null ? data.longitude : 0;
      this.requetsObj.country = data !== null ? data.country : "";
      this.requetsObj.geofenceRangeDiameter =
        data !== null ? data.selectedRadius : "";
      this.requetsObj.fullAddress = data !== null ? data.fullAddress : "";
      this.requetsObj.houseNo = data !== null ? data.houseNo : "";
      this.requetsObj.floorNumber = data !== null ? data.floorNumber : "";
      this.requetsObj.buildingName = data !== null ? data.buildingName : "";
      this.requetsObj.directionNote = data !== null ? data.directionNote : "";
      this.requetsObj.contactNumber = data !== null ? data.contactNumber : "";
      this.requetsObj.contactName = data !== null ? data.contactName : "";
      this.requetsObj.addressTitle = data.addressTitle  ? data.addressTitle : "";
    },
    getCustLeadInfo(custId) {
      this.$store.dispatch("SetLoader", {
        status: true,
        message: "Loading...",
      });
      MyJobApp.getCustomerDetail(
        custId,
        (response) => {
          this.searchForCustomer = response.Data.companyName;
          this.requetsObj.customerId = response.Data.customerId;
          this.showAddSelectAddr = true;
          this.getCustDetails(response.Data.customerId);
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    selectReqAddress(data) {
      this.errObj.addErr = "";
      this.jobDisplayAdd = data !== null ? data.fullAddress : "";
      this.requetsObj.fullAddress = data !== null ? data.fullAddress : "";
      this.requetsObj.addressLine1 = data !== null ? data.addressLine1 : "";
      this.requetsObj.addressLine2 = data !== null ? data.addressLine2 : "";
      this.requetsObj.city = data !== null ? data.city : "";
      this.requetsObj.state = data !== null ? data.state : "";
      this.requetsObj.zip = data !== null ? data.zip : "";
      this.requetsObj.latitude = data !== null ? data.latitude : 0;
      this.requetsObj.longitude = data !== null ? data.longitude : 0;
      this.requetsObj.country = data !== null ? data.country : "";
      this.requetsObj.directionNote = data !== null ? data.directionNote : "";
      this.requetsObj.houseNo = data !== null ? data.houseNo : "";
      this.requetsObj.floorNumber = data !== null ? data.floorNumber : "";
      this.requetsObj.buildingName = data !== null ? data.buildingName : "";
    },
    selsectFromMap() {
      this.isSelectFromMap = true;
    },
    checkboxCheck() {
      this.isAddedNewAddress = false;
      this.isOfficeAddressSel = true;
      this.errObj.addErr = "";
      this.jobDisplayAdd = "";
      this.requetsObj.fullAddress = "";
      this.requetsObj.addressLine1 = "";
      this.requetsObj.addressLine2 = "";
      this.requetsObj.city = "";
      this.requetsObj.state = "";
      this.requetsObj.zip = "";
      this.requetsObj.latitude = 0;
      this.requetsObj.longitude = 0;
      this.requetsObj.country = "";
      this.requetsObj.directionNote = "";
      this.requetsObj.houseNo = "";
      this.requetsObj.floorNumber = "";
      this.requetsObj.buildingName = "";
    },
    selectNewCustomer(data) {
      this.errObj.addressErr = "";
      if (data !== null) {
        this.islead = data.isLead;
        this.jobDisplayAdd = "";
        this.requetsObj.addressLine1 = "";
        this.requetsObj.addressLine2 = "";
        this.requetsObj.city = "";
        this.requetsObj.state = "";
        this.requetsObj.zip = "";
        this.requetsObj.latitude = 0;
        this.requetsObj.longitude = 0;
        this.requetsObj.country = "";
        this.requetsObj.directionNote = "";
        this.requetsObj.houseNo = "";
        this.requetsObj.floorNumber = "";
        this.requetsObj.buildingName = "";
        this.errObj.custErr = "";
        this.errObj.addErr = "";
        this.searchForCustomer = data.companyName;
        this.requetsObj.customerId = data.customerId;
        this.showAddSelectAddr = true;
        this.getCustDetails(data.customerId);
      } else {
        this.searchForCustomer = "";
        this.requetsObj.customerId = 0;
      }
    },
    addNewCustomer() {
      this.addCustomer = true;
    },
    addNewAddress() {
      this.addNewReqAdd = true;
    },
    callsearchCust(text) {
      this.searchApitext = text;
      this.getCustList();
    },
    getCustomerList() {
      this.customersList = [];
      MyJobApp.getGlobalCustLeadList(
        0,
        500,
        this.searchApitext,
        "",
        (response) => {
          let tempList =
            response.Data.tableRow !== null ? response.Data.tableRow : [];
          tempList.forEach((e) => {
            let lead = e.isLead ? "(lead)" : "(customer)";
            e.companyName = e.companyName + " " + lead;
            this.customersList.push(e);
          });
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.resetPaginationValues = false;
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    resizeWindowHandler() {
      this.cardHeight = window.innerHeight - 158;
    },
    getCustDetails(custId) {
      this.$store.dispatch("SetLoader", {
        status: true,
        message: "Loading...",
      });
      MyJobApp.getCustomerDetail(
        custId,
        (response) => {
          this.addressList = [];
          this.custDetail = response.Data
          if (response.Data.addressList !== null) {
            response.Data.addressList.forEach((e) => {
              let temp = 0;
              let temp3 = "";
              if (this.LoginUserlatLong !== null) {
                temp = this.calcDistance(
                  this.LoginUserlatLong.latitude,
                  this.LoginUserlatLong.longitude,
                  e.latitude,
                  e.longitude
                );
                temp3 = ` from user`;
              } else {
                temp = this.calcDistance(
                  this.OfficelatLong.latitude,
                  this.OfficelatLong.longitude,
                  e.latitude,
                  e.longitude
                );
                temp3 = ` from office`;
              }

              let value = this.getDistanceUnit(temp * 1000);

              this.addressList.push({
                ...e,
                selected: false,
                displayDistance: value + temp3,
                addressText:
                  `(${e.addressTitle === "" ? "Other" : e.addressTitle}) -` +
                  ` ${e.fullAddress}`,
              });
            });
            // this.addressList = response.Data.addressList !== null ? response.Data.addressList : [];
          }
          if (this.addressList.length === 1 && this.requetsObj.requestId === 0) {
            this.jobDisplayAdd = this.addressList[0].fullAddress;
            this.requetsObj.fullAddress = this.addressList[0].fullAddress;
            this.requetsObj.addressLine1 = this.addressList[0].addressLine1;
            this.requetsObj.addressLine2 = this.addressList[0].addressLine2;
            this.requetsObj.city = this.addressList[0].city;
            this.requetsObj.state = this.addressList[0].state;
            this.requetsObj.zip = this.addressList[0].zip;
            this.requetsObj.latitude = this.addressList[0].latitude;
            this.requetsObj.longitude = this.addressList[0].longitude;
            this.requetsObj.country = this.addressList[0].country;
            this.requetsObj.directionNote = this.addressList[0].directionNote;
            this.requetsObj.houseNo = this.addressList[0].houseNo;
            this.requetsObj.floorNumber = this.addressList[0].floorNumber;
            this.requetsObj.buildingName = this.addressList[0].buildingName;
          } else if (this.requetsObj.requestId === 0 || this.reqTempObj.customerId !== custId) {
            this.deleteSelectedAddress()
          }
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
     getEmployeeList () {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.getEmployeeList(
        'active',
        response => {
          let tempList = []
          if (response.Data.tableRow !== null && this.reqTempObj == null) {
            response.Data.tableRow.map(el => {
               tempList.push({...el, fullName: el.firstName + ' ' + el.lastName})
               if (this.logedInUser === el.userDetailId && this.selectedEmployee.length === 0) {
              this.selectedEmployee.push({...el, fullName: el.firstName + ' ' + el.lastName})
            }
            })
            
          } else if (response.Data.tableRow !== null) {
              this.selectedEmployee = []
              this.stepTwoObj.assignToList = []
              let tempEmpList = []
                response.Data.tableRow.forEach(e => {
                  tempList.push({...e, fullName: e.firstName + ' ' + e.lastName})
                  const ind = this.reqTempObj.assignToList.findIndex((x) => x.userDetailId === e.userDetailId);
                  if (ind >= 0) {
                    tempEmpList.push({userDetailId:e.userDetailId})
                  this.selectedEmployee.push({...e, fullName: e.firstName + ' ' + e.lastName})
                  }
                });
              this.stepTwoObj.assignToList = tempEmpList
          }
          this.employeeList = tempList

        
          
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    getDistanceUnit(distanceMeter) {
      let distance = "";
      let countryCode;
      const moment = require("moment-timezone");
      const countryTimeZone = moment.tz.guess(); // Guesses the user's time zone
      timeZoneWithCountryCode.map((itm) => {
        const ind = itm.timezones.findIndex((x) => x.name === countryTimeZone);
        if (ind >= 0) {
          countryCode = itm.code;
        }
      });
      const index = milesCountry.findIndex(
        (x) => x.countryCode === countryCode
      );
      if (index >= 0) {
        if (distanceMeter * 0.00062137 >= 1000) {
          distance = `999+ mi`;
        } else if (distanceMeter * 0.00062137 < 0.1) {
          distance = "";
        } else {
          distance = `${(distanceMeter * 0.00062137).toFixed(1)} mi`;
        }
      } else if (distanceMeter / 1000 >= 1000) {
        distance = `999+ km`;
      } else if (distanceMeter / 1000 < 0.1) {
        distance = "";
      } else {
        distance = `${(distanceMeter / 1000).toFixed(1)} km`;
      }
      return distance;
    },
    calcDistance(lat1, lon1, lat2, lon2) {
      const radius = 6371; // Earth's radius in km
      const dLat = this.toRadians(lat2 - lat1);
      const dLng = this.toRadians(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.toRadians(lat1)) *
          Math.cos(this.toRadians(lat2)) *
          Math.sin(dLng / 2) *
          Math.sin(dLng / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = radius * c;
      return distance.toFixed(2);
    },
    toRadians(degree) {
      return degree * (Math.PI / 180);
    },
  },
};
</script>
<style scoped>
</style>